import React, { FC, useRef, useState } from 'react';
import Container from '../Reimbursement/Container';
import {
  MonoFontLabel,
  OpenSansParagraph,
  RecoletaHeading,
} from '../common/typography';
import { Col, Row } from 'emotion-flex';
import { markdownText } from '../../pages/doctors';
import SliderArrow from '../common/slider/SliderArrow';
import styled from '@emotion/styled';
import presets from '../../styles/presets';
import { Global, css } from '@emotion/core';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { ReactComponent as TopWave } from '../../assets/svgs/doctors/outcomesTop.svg';
import { ReactComponent as BottomWave } from '../../assets/svgs/doctors/outcomesBottom.svg';
import Video from '../Video';
import { ButtonLink } from '../common/Button';

const Dot = styled.div<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  background-color: #489f9d;
  border-radius: 50%;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  margin-bottom: 25px;
  transition: opacity 0.5s ease-in;
  cursor: pointer;
`;

export interface FeaturesData {
  prefix: string;
  title: string;
  description: string;
  phoneImage: string;
  bgImage: string;
}

interface Props {
  title: string;
  description: string;
  sliderTitle: string;
  dataObj: FeaturesData[];
  ctaTitle: string;
  ctaDescription: string;
  primaryCTAButtonText: string;
  primaryCTAButtonLink: string;
}

const NewTracking: FC<Props> = ({
  dataObj,
  title,
  description,
  sliderTitle,
  ctaTitle,
  ctaDescription,
  primaryCTAButtonLink,
  primaryCTAButtonText,
}) => {
  const DURATION = 10000;

  const [activeIndex, setActiveIndex] = useState(0);
  const intervalRef = useRef(null);

  const goToSlide = (index: number) => {
    setActiveIndex(index);
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(
      () => goToSlide((index + 1) % dataObj.length),
      DURATION
    );
  };

  const isMobile: boolean =
    typeof window !== 'undefined' &&
    window.matchMedia('@media (max-width: 1000px)').matches;

  return (
    <>
      <TopWave
        width="100vw"
        height="auto"
        css={{
          display: 'block',
          width: '100%',
          height: 'auto',
          marginTop: '4rem',
        }}
      />
      <div
        css={{
          marginTop: -1,
          backgroundColor: '#E8F1F0',
          paddingBottom: 30,
        }}
      >
        <Container css={{ maxWidth: 1000 }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              marginBottom: '2rem',
            }}
          >
            <RecoletaHeading
              css={{
                fontSize: '32px',
                fontWeight: 500,
                lineHeight: '38px',
                maxWidth: 400,
              }}
            >
              {markdownText(title)}
            </RecoletaHeading>

            <OpenSansParagraph
              css={{
                maxWidth: 400,
              }}
            >
              {markdownText(description)}
            </OpenSansParagraph>
          </div>
          <div
            css={{
              maxWidth: 730,
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '2rem',
            }}
          >
            <Video videoSrcURL={'https://www.youtube.com/embed/8iHMcpi541g'} />
          </div>

          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <RecoletaHeading
              css={{
                position: 'relative',
                left: 0,
                top: 0,
                fontSize: '32px',
                fontWeight: 500,
                lineHeight: '39px',
                textAlign: 'center',
                marginBottom: '2rem',

                [presets.lg]: {
                  left: '-20%',
                  top: '8.5rem',
                  textAlign: 'left',
                  marginBottom: 0,
                },
              }}
            >
              {markdownText(sliderTitle)}
            </RecoletaHeading>
          </div>

          <Row css={{ alignItems: 'center' }}>
            <Col
              xs={12}
              lg={5}
              css={{
                marginBottom: 84,
                [presets.lg]: {
                  marginBottom: 0,
                },
              }}
            >
              <div
                css={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  minHeight: '500px',
                  [presets.lg]: {
                    minHeight: 600,
                  },
                }}
              >
                <img
                  src={dataObj[activeIndex].bgImage}
                  alt="phone"
                  css={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: 500,
                    [presets.lg]: {
                      height: 1042,
                    },
                  }}
                />

                {dataObj.map((item, i) => (
                  <div
                    key={i}
                    css={[
                      {
                        opacity: 0,
                        position: 'absolute',
                        width: 260,
                        height: 588,
                        left: '50%',
                        marginLeft: -137, // width / 2
                        top: '50%',
                        marginTop: -287, // height / 2
                        zIndex: 1,
                        transition: 'opacity 1.5s ease-in',

                        [presets.lg]: {
                          width: 260,
                          marginLeft: -137, // width / 2
                          marginTop: -287, // height / 2
                        },
                      },
                      i === activeIndex && {
                        opacity: 1,
                      },
                    ]}
                  >
                    <img
                      src={item.phoneImage}
                      alt={item.title}
                      css={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                ))}
              </div>
              <div
                css={{
                  display: 'none',
                  visibility: 'hidden',
                  [presets.lg]: {
                    display: 'flex',
                    visibility: 'visible',
                    flexDirection: 'row',
                    position: 'absolute',
                    bottom: '-3%',
                    left: '35%',
                    right: 20,
                    transform: 'translateY(-50%)',
                  },
                }}
              >
                {dataObj.map((item, i) => (
                  <Dot
                    css={{
                      margin: '0 0.5rem',
                    }}
                    key={`dot-${i}`}
                    active={i === activeIndex}
                    onClick={() => goToSlide(i)}
                  />
                ))}
              </div>
            </Col>

            <Col xs={12} lg={7} lgOrder="first">
              <Global
                styles={css`
                  .slide-in-fade-out-enter {
                    opacity: 0;
                    ${presets.lg} {
                      transform: translateY(64px);
                    }
                  }
                  .slide-in-fade-out-exit {
                    opacity: 1;
                    transform: translateY(0);
                  }
                  .slide-in-fade-out-enter-active {
                    opacity: 1;
                    transform: translateY(0);
                  }
                  .slide-in-fade-out-exit-active {
                    opacity: 0;
                    transform: translateY(0);
                  }
                  .slide-in-fade-out-enter-active {
                    transition: opacity 750ms ease-in-out, transform 750ms ease;
                  }

                  .slide-in-fade-out-exit-active {
                    transition: opacity 400ms ease-out;
                  }

                  path#carousel-bg {
                    transition: fill 750ms ease-out 400ms;
                  }
                `}
              />

              <SwitchTransition mode="out-in">
                <CSSTransition<undefined>
                  key={activeIndex}
                  classNames="slide-in-fade-out"
                  unmountOnExit
                  addEndListener={(node, done) => {
                    node.addEventListener('transitionend', done, false);
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      textAlign: 'center',
                      margin: '0 auto',

                      [presets.lg]: {
                        maxWidth: 'none',
                        margin: 0,
                        textAlign: 'left',
                      },
                    }}
                  >
                    {isMobile && (
                      <>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: '1rem',
                          }}
                        >
                          <SliderArrow
                            position="left"
                            onClick={() => {
                              if (activeIndex === 0) {
                                goToSlide(dataObj.length - 1);
                              } else {
                                goToSlide(activeIndex - 1);
                              }
                            }}
                          />
                          {dataObj.map((item, index) => (
                            <Dot
                              css={{
                                margin: '0 0.5rem',
                              }}
                              key={index}
                              active={index === activeIndex}
                              onClick={() => goToSlide(index)}
                            />
                          ))}
                          <SliderArrow
                            position="right"
                            onClick={() => {
                              if (activeIndex === dataObj.length - 1) {
                                goToSlide(0);
                              } else {
                                goToSlide(activeIndex + 1);
                              }
                            }}
                          />
                        </div>
                        <div
                          css={{
                            width: '100%',
                          }}
                        >
                          <MonoFontLabel
                            css={{
                              fontWeight: 700,
                              letterSpacing: '2px',
                              lineHeight: '18px',
                            }}
                          >
                            {dataObj[activeIndex].prefix}
                          </MonoFontLabel>
                          <RecoletaHeading
                            css={{
                              fontSize: '24px',
                              fontWeight: 600,
                              padding: '0 2rem',
                            }}
                          >
                            {dataObj[activeIndex].title}
                          </RecoletaHeading>
                          <OpenSansParagraph
                            css={{
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '25px',
                              marginTop: '1rem',
                              padding: '0 1rem',
                            }}
                          >
                            {dataObj[activeIndex].description}
                          </OpenSansParagraph>
                        </div>
                      </>
                    )}
                    {!isMobile && (
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <SliderArrow
                          position="left"
                          onClick={() => {
                            if (activeIndex === 0) {
                              goToSlide(dataObj.length - 1);
                            } else {
                              goToSlide(activeIndex - 1);
                            }
                          }}
                        />
                        <div
                          css={{
                            width: '100%',
                            padding: '0 2rem',
                          }}
                        >
                          <MonoFontLabel
                            css={{
                              fontWeight: 700,
                              letterSpacing: '2px',
                              lineHeight: '18px',
                            }}
                          >
                            {dataObj[activeIndex].prefix}
                          </MonoFontLabel>
                          <RecoletaHeading
                            css={{
                              fontSize: '24px',
                              fontWeight: 600,
                              maxWidth: 400, // will be different per section
                            }}
                          >
                            {dataObj[activeIndex].title}
                          </RecoletaHeading>
                          <OpenSansParagraph
                            css={{
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '25px',
                              marginTop: '1rem',
                            }}
                          >
                            {dataObj[activeIndex].description}
                          </OpenSansParagraph>
                        </div>
                        <SliderArrow
                          position="right"
                          onClick={() => {
                            if (activeIndex === dataObj.length - 1) {
                              goToSlide(0);
                            } else {
                              goToSlide(activeIndex + 1);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </Col>
          </Row>

          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              marginTop: '3.5rem',
            }}
          >
            <RecoletaHeading
              css={{
                fontSize: '32px',
                fontWeight: 500,
                lineHeight: '38px',
                maxWidth: 400,
              }}
            >
              {markdownText(ctaTitle)}
            </RecoletaHeading>

            <OpenSansParagraph
              css={{
                maxWidth: 400,
              }}
            >
              {markdownText(ctaDescription)}
            </OpenSansParagraph>
            <ButtonLink
              css={{
                marginTop: 5,
                padding: '1rem 1rem',
                borderRadius: 8,
                fontWeight: 'bold',
              }}
              to={primaryCTAButtonLink}
            >
              {primaryCTAButtonText}
            </ButtonLink>
          </div>
        </Container>
      </div>

      <BottomWave
        width="100%"
        height="83"
        css={{ width: '100%', height: 'auto' }}
      />
    </>
  );
};

export default NewTracking;
